.mat-checkbox {
    padding-right: 10px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #ec9a1a;
}

.form button {
    width: 100%;
}

@media (max-width:768px) {
    .grid-button-margin {
        margin-top: 0px !important;
    }
}

.grid-button-margin {
    margin-top: 15px !important;
}

.mat-form-field {
    width: 100%;
    margin-top: 20px;
}

.mat-form-field-label {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #000 !important;
}

.form .mat-form-field-wrapper{
    padding-bottom: 0px !important;
    margin: 0px !important;
}

.mat-form-field-flex {
    padding-top: 0px !important;
}

.form input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([readonly]),
.form .mat-select {
    background-color: transparent !important;
    height: 25px !important;
    outline: none;
}

.form .mat-select .mat-select-arrow-wrapper {
    transform: translateY(8%);
    align-self: baseline;
}

.form p .submit {
    margin-top: 2px;
    height: 30px;
}

.form p input.submit {
    line-height: 30px;
    margin-top: 2px !important;
    height: 30px !important;
}

.form .mat-form-field-infix {
    padding: 0 !important;
    border-top: 0.4em solid transparent;
    border-bottom: 0.2em solid transparent;
}

.form .mat-form-field-outline-gap {
    width: 0 !important;
}

.form .mat-form-field-label-wrapper {
    top: -1.5em;
    left: -10px !important;
}

.form .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: rgba(0, 0, 0, .12);
}

.form .mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
    color: #ec9a1a;
    opacity: 1;
}

.form .mat-focused .mat-select-arrow,
.form .mat-focused .mat-form-field-required-marker {
    color: #ec9a1a !important;
}

.form .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-has-label .mat-form-field-label {
    transform: translateY(-1.59375em) scale(0.75);
    width: 133.3333333333%;
}

.form .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
    border-top-color: rgba(0, 0, 0, .12);
}

.form .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: orange !important;
}


.form .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: orange;
    outline: 0 !important;
}

.form .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: #f44336;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #f57e04;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: #f1a75c;
}