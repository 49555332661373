/*
Global CSS
*/
/* 
    Created on : March 2024, 12:43:16 PM
    Author     : Riya Salunkhe
*/
/*Common Styles*/
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.core.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import url('./assets/css/form.styles.css');
@import url('./assets/css/page-header.styles.css');
@import url('./assets/css/table.styles.css');
@import url('./assets/css/grid.styles.css');

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

ul {
    margin: 0;
    padding: 0;
    list-style: outside none none;
}

a:focus,
a:hover {
    text-decoration: none;
    outline: 0;
}

img {
    height: auto;
    max-width: 100%;
    width: auto;
}

b,
strong {
    font-weight: 400;
}

th {
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    line-height: 1.1;
    font-weight: 400;
}

input.error::placeholder {
    color: #FF0000;
}

body {
    font-family: 'Heebo', sans-serif;
    color: #000000;
    font-size: 15px;
    font-weight: 300;
    background-color: #fafafa;
}

body p {
    margin-bottom: 15px;
}

mat-icon {
    color: #f57e04;
    font-size: 20px !important;
    height: 20px !important;
    width: 20px !important;
}

.fa-refresh,
.fa-download {
    color: #f57e04;
    cursor: pointer;
}

/* scrollbar styling start */
/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* scrollbar styling end */

.mr-2 {
    margin-right: 16px !important;
}

.ml-2 {
    margin-left: 16px !important;
}

.mt-2 {
    margin-top: 16px !important;
}

.mb-2 {
    margin-bottom: 16px !important;
}

.mx-2 {
    margin: 0px 16px !important;
}

.my-2 {
    margin: 16px 0px !important;
}

.pr-2 {
    padding-right: 16px !important;
}

.pl-2 {
    padding-left: 16px !important;
}

.pt-2 {
    padding-top: 16px !important;
}

.pb-2 {
    padding-bottom: 16px !important;
}

.px-2 {
    padding: 0px 16px !important;
}

.py-2 {
    padding: 16px 0px !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.cursor-pointer {
    cursor: pointer;
}

:after,
:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.red-snackbar {
    background: #f33a21 !important;
}