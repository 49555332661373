.page-header-container {
    display: flex;
    justify-content: space-between; /* Add space between items */
}

.page-header-container h1.header {
    color: #82b741;
    flex-grow: 1; /* Allow the item to grow and take available space */
}

.page-header-wrapper {
    margin-bottom: 16px;
}

.page-header-wrapper .mat-card {
    min-height: 60px;
}

.page-filter-container {
    border-top: 1px solid #0000001f;
    margin-top: 16px; /* To add space between page-header-container and border */
    padding-top: 16px; /* To add space between page-filter-container items and border */
}

@media only screen and (max-width: 600px) {
    input {
        width: 100% !important;
    }
}

.btn-div button {
    min-width: 150px !important;
    margin-right: 8px;
}

.btn-div button:last-child {
    margin-right: 0px;
}

@media (max-width: 768px) {
    .btn-div button {
        min-width: fit-content !important;
        margin: 0px;
    }

    .btn-div {
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: wrap;
        gap: 8px;
    }
}